import {Parameters} from 'platform/components';
import {Align, Display, HStack, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {UseFormReturn} from 'react-hook-form';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {VehicleLogo} from '../../../components/VehicleLogo/VehicleLogo';
import {useCatalogue} from '../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../hooks/useVehicleTranslatedInfo';
import {VEHICLETYPE_PASSENGER_CAR} from '../constants/vehiclePassengerCar';
import {VehicleFormType} from '../types/VehicleFormType';

interface VehicleHeaderProps extends TestIdProps {
  formApi: UseFormReturn<VehicleFormType>;
}

export function VehicleHeader(props: VehicleHeaderProps) {
  const catalogue = useCatalogue();

  const formatNumber = useFormatNumber();

  const vehicle = props.formApi.watch() as VehicleFormType;

  const {data} = useVehicleTranslatedInfo({...vehicle, vehicleType: VEHICLETYPE_PASSENGER_CAR});

  const parameters: Array<string | Nullish> = [
    vehicle.firstRegistrationOnYear?.toString(),
    isNotNil(vehicle.mileage)
      ? `${formatNumber(vehicle.mileage)} ${i18n.t('general.metric.km')}`
      : null,
    isNotNil(vehicle.power) ? `${vehicle.power} ${i18n.t('general.metric.kW')}` : null,
    catalogue.getTransmission(vehicle.transmission?.[0]),
    catalogue.getFuelType(vehicle.fuelType?.[0]),
    catalogue.getDrive(vehicle.drive?.[0]),
    catalogue.getCarStyle(vehicle.bodyStyle?.[0]),
  ].filter(isNotNilOrEmpty);

  return (
    <VStack align="center" spacing={4}>
      <HStack wrap spacing={4} align="center" justify="center">
        <VehicleLogo
          vehicle={{...vehicle, vehicleType: VEHICLETYPE_PASSENGER_CAR}}
          height={14}
          width={14}
          color="white"
          data-testid={suffixTestId('vehicleLogo', props)}
        />
        <Align center>
          <Display
            size={1}
            color="white"
            data-testid={suffixTestId('vehicleHeading', props)}
          >{`${data.make} ${data.model}`}</Display>
        </Align>
      </HStack>
      <Align center>
        <Parameters
          parameters={parameters}
          color="white"
          size="base"
          justify="center"
          data-testid={suffixTestId('vahicleParameters', props)}
        />
      </Align>
    </VStack>
  );
}
