import {Image, Integer} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isEmpty, isNil} from 'ramda';

import {VehicleType} from '@price-report/shared';

import {TestIdProps} from 'shared';

import {useVehicleTranslatedInfo} from '../../hooks/useVehicleTranslatedInfo';

interface VehicleLogoProps extends TestIdProps {
  vehicle: Pick<VehicleType, 'make' | 'modelFamily' | 'vehicleType'>;
  height?: Integer;
  width?: Integer;
  color?: 'white' | 'black' | 'default';
}

export function VehicleLogo(props: VehicleLogoProps) {
  const {data} = useVehicleTranslatedInfo(props.vehicle);

  const logoUrl = match(props.color ?? 'default')
    .with('default', () => data.logoUrl)
    .with('white', () => data.whiteLogoUrl)
    .with('black', () => data.blackLogoUrl)
    .exhaustive();

  if (isNil(logoUrl) || isEmpty(logoUrl)) {
    return null;
  }

  return (
    <Image
      src={logoUrl}
      height={props.height}
      width={props.width}
      fit="contain"
      data-testid={props['data-testid']}
    />
  );
}
