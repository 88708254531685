import {DataStatus} from 'platform/components';
import {Box, useDevice} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {isEmpty, isNotNil, sortBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {AvailableCar, i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps, useQueryState} from 'shared';

import {DesktopPagination} from '../../components/Pagination/DesktopPagination';
import {MobilePaginationWithFilterBar} from '../../components/Pagination/MobilePaginationWithFilterBar';
import {VehicleInfo} from '../../components/VehicleInfo/VehicleInfo';
import {useFilter} from '../../hooks/FilterContext';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {useVehicleTranslatedInfo} from '../../hooks/useVehicleTranslatedInfo';
import {
  useGetAvailableCarsQuery,
  useGetPriceReportCarsDetailQuery,
} from '../../store/priceReportApi';
import {AdvertisementColumnLayout} from './components/AdvertisementColumnLayout';
import {AdvertisementRowLayout} from './components/AdvertisementRowLayout';
import {DirectionSwitch} from './components/DirectionSwitch';
import {PAGE_SIZE} from './consts/pageSize';
import {AvailableCarWithLogo} from './types/available-car-with-logo';
import {Direction} from './types/direction';
import {formatMyVehicle} from './utils/formatMyVehicle';
import {getDefaultDirection} from './utils/getDefaultDirection';

export function AdvertisementPage(props: TestIdProps) {
  const {data: priceReport} = usePriceReport();
  const catalogue = useCatalogue();
  const {filter, errors: filterErrors} = useFilter();
  const device = useDevice();
  const [page, setPage] = useState<number | null>(null);
  const [direction, setDirection] = useState<Direction>(getDefaultDirection(device));
  const theme = useTheme();

  const [debug] = useQueryState('debug');
  const isDebug = debug === 'true';
  const {data: neighbours, isLoading: areNeighboursLoading} = useGetPriceReportCarsDetailQuery(
    {reportId: priceReport?.id ?? '', vehicleIds: priceReport?.neighborAdIds ?? []},
    {skip: !isDebug || isNilOrEmpty(priceReport?.neighborAdIds)}
  );

  useEffect(() => {
    setPage(null);
  }, [filter]);

  useEffect(() => {
    setDirection(getDefaultDirection(device));
  }, [device]);

  const {data, isFetching, isLoading, isError} = useGetAvailableCarsQuery(
    {
      id: priceReport?.id ?? null,
      body: {
        size: PAGE_SIZE,
        query_price: priceReport?.sellingPrice.recommended.value,
        offset: page !== null ? (page - 1) * PAGE_SIZE : undefined,
        ...filter,
      },
    },
    {skip: !priceReport?.id || !filter}
  );

  const myVehicleTranslatedInfo = useVehicleTranslatedInfo(priceReport?.vehicle);

  // TODO T20-28291 it should not be error
  const isMetadaError = isError && filter?.hideStockCars && filter?.hideSoldCars;

  const availableCars = isMetadaError || filterErrors ? [] : (data?.result ?? []);

  const currentPage = isDebug ? 1 : (data?.current_page ?? 0);
  const pagesQuantity = isDebug ? 1 : (data?.total_pages ?? 0);
  const results = isDebug ? (neighbours?.length ?? 0) : (data?.total_hits ?? 0);

  if (!priceReport) {
    return null;
  }

  const myVehicle = {
    isMyVehicle: true,
    logoUrl: myVehicleTranslatedInfo?.data?.logoUrl,
    ...formatMyVehicle(priceReport),
  };

  const sortedAndMappedNeighbours: AvailableCarWithLogo[] =
    priceReport?.neighborAdIds
      ?.map((id) => neighbours?.find((car) => car.ad_id === id))
      .filter((car) => isNotNil(car))
      .map((car) => ({...(car as AvailableCar), isMyVehicle: false})) ?? [];

  const availableCarsWithMyVehicle: AvailableCarWithLogo[] = !isDebug
    ? sortBy(
        (a) => a.original_price_with_vat ?? 0,
        [myVehicle, ...availableCars.map((car) => ({...car, isMyVehicle: false}))]
      )
    : [myVehicle, ...sortedAndMappedNeighbours];

  const getVehiclePosition = (vehicle: AvailableCarWithLogo, index: number) =>
    vehicle.isMyVehicle
      ? (data?.query_original_price_index ?? 0) + 1
      : index + PAGE_SIZE * ((currentPage ?? 0) - 1) + 1;

  return (
    <>
      <Helmet title={i18n.t('page.advertisiments.title')} />
      <MobilePaginationWithFilterBar
        page={currentPage}
        onPageChange={setPage}
        pagesQuantity={pagesQuantity}
        results={results}
        isLoading={isFetching || isLoading}
      >
        <DirectionSwitch
          direction={direction}
          setDirection={setDirection}
          data-testid={suffixTestId('mobile', props)}
        />
      </MobilePaginationWithFilterBar>
      <Box padding={2}>
        <Box
          borderRadius={theme.components.Card.borderRadius}
          boxShadow={theme.components.Card.elevation}
          backgroundColor="general.white"
          padding={direction === 'column' ? 0 : 4}
          paddingTop={4}
        >
          <Box paddingHorizontal={direction === 'column' ? 4 : 0} paddingBottom={4}>
            <VehicleInfo
              catalogue={catalogue}
              vehicle={priceReport.vehicle}
              data-testid={suffixTestId('vehicleInfo', props)}
            >
              <DesktopPagination
                page={currentPage}
                onPageChange={setPage}
                pagesQuantity={pagesQuantity}
                results={results}
                data-testid={suffixTestId('desktop', props)}
              >
                <DirectionSwitch
                  direction={direction}
                  setDirection={setDirection}
                  data-testid={suffixTestId('desktop', props)}
                />
              </DesktopPagination>
            </VehicleInfo>
          </Box>
          <DataStatus
            isLoading={isFetching || isLoading || areNeighboursLoading}
            isError={isError && !isMetadaError}
            isEmpty={isEmpty(availableCarsWithMyVehicle)}
            spacing={12}
          >
            {direction === 'row' ? (
              <AdvertisementRowLayout
                vehicles={availableCarsWithMyVehicle}
                data-testid={suffixTestId('rowLayout', props)}
              />
            ) : (
              <AdvertisementColumnLayout
                vehicles={availableCarsWithMyVehicle}
                getVehiclePosition={getVehiclePosition}
                data-testid={suffixTestId('columnLayout', props)}
              />
            )}
          </DataStatus>
        </Box>
      </Box>
    </>
  );
}
